/* eslint-disable */

<template>
  <div class="vx-col">
    <vx-card class="mb-base">
      <div class="tenth-block-2 rounded mb-5" style="background-color: #040815">
        <h1 class="tenth-block-text-small-2">1500 Broadway, New York, NY 10036<br /></h1>
      </div>

      <div class="w-full flex justify-center relative click-item-container">
        <div class="image-container" style="position: relative">
          <img
            src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335452482824044544/WifiInstallationGraphic.png"
            draggable="false"
            style="width: 100%; height: 100%"
          />
          <span class="click-item" style="left: 1.7%; top: 4.3%" @click="wifi_controller.active = !wifi_controller.active"></span>
          <span class="click-item" style="left: 1.7%; bottom: 4.3%" @click="radius_server.active = !radius_server.active"></span>
          <span
            class="click-item"
            style="right: 2.2%; bottom: 28%; height: 20rem; width: 10rem; border-radius: 1rem"
            @click="wireless_client.active = !wireless_client.active"
          ></span>
        </div>
      </div>
    </vx-card>

    <movable-popup
      refVal="movableWiFiControllerRef"
      title="Cisco 5520 WLAN Controller - Drag Here To Move"
      :active="wifi_controller.active"
      v-on:close="wifi_controller.active = false"
    >
      <template v-slot:content>
        <div class="popup-container">
          <div v-for="field in wifi_controller.properties" :key="field.name" class="flex flex-col">
            <div class="">
              <h5 class="break-words mb-1">{{ field.name }}</h5>

              <v-select
                v-if="field.type === 'dropdown'"
                :options="field.options"
                v-model="field.value"
                :disabled="field.disabled"
                class="w-full popup-dropdown"
                :placeholder="'Select ' + field.name"
              />
              <vs-input
                v-if="field.type === 'text'"
                v-model="field.value"
                :readonly="field.disabled"
                class="inputx w-full self-center popup-input"
              />
              <div class="flex items-center" v-if="field.type === 'switch'">
                <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
              </div>
              <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
              <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
            </div>
          </div>
        </div>
      </template>
    </movable-popup>
    <movable-popup
      refVal="movableRadiusServerRef"
      title="Juniper Networks AAA Server - Drag Here To Move"
      :active="radius_server.active"
      v-on:close="radius_server.active = false"
    >
      <template v-slot:content>
        <div class="popup-container">
          <div v-for="field in radius_server.properties" :key="field.name" class="flex flex-col">
            <div>
              <h5 class="break-words mb-1">{{ field.name }}</h5>

              <v-select
                v-if="field.type === 'dropdown'"
                :options="field.options"
                v-model="field.value"
                :disabled="field.disabled"
                class="w-full popup-dropdown"
                :placeholder="'Select ' + field.name"
              />
              <vs-input
                v-if="field.type === 'text'"
                v-model="field.value"
                :readonly="field.disabled"
                class="inputx w-full self-center popup-input"
              />
              <div class="flex items-center" v-if="field.type === 'switch'">
                <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
              </div>
              <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
              <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
            </div>
          </div>
        </div>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableRadiusServerRef"
      title="Starbucks Staff Device - Drag Here To Move"
      :active="wireless_client.active"
      v-on:close="wireless_client.active = false"
    >
      <template v-slot:content>
        <div class="popup-container">
          <div v-for="field in wireless_client.properties" :key="field.name" class="flex flex-col">
            <div>
              <h5 class="break-words mb-1">{{ field.name }}</h5>

              <v-select
                v-if="field.type === 'dropdown'"
                :options="field.options"
                v-model="field.value"
                :disabled="field.disabled"
                class="w-full popup-dropdown"
                :placeholder="'Select ' + field.name"
              />
              <vs-input
                v-if="field.type === 'text'"
                v-model="field.value"
                :readonly="field.disabled"
                class="inputx w-full self-center popup-input"
              />
              <div class="flex items-center" v-if="field.type === 'switch'">
                <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
              </div>
              <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
              <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
            </div>
          </div>
        </div>
      </template>
    </movable-popup>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import MovablePopup from '../../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      wifi_controller: {
        active: false,
        properties: [
          {
            name: 'WiFi SSID',
            type: 'text',
            value: '',
            answer: 'SBUX-BYOD-WIFI',
            disabled: false,
            score: null,
          },
          {
            name: 'AAA Server Address',
            type: 'text',
            value: '',
            answer: '10.0.100.13',
            disabled: false,
            score: null,
          },
          {
            name: 'AAA Server Port Number',
            type: 'text',
            value: '',
            answer: '1812',
            disabled: false,
            score: null,
          },
          {
            name: 'PSK',
            type: 'text',
            value: '',
            answer: 'PV6JYNYQ2X',
            disabled: false,
            score: null,
          },
          {
            name: 'Authentication Type',
            type: 'dropdown',
            value: '',
            answer: 'WPA2-PSK',
            disabled: false,
            options: ['EAP-POTP', 'WPA3', 'OPEN', 'WPA-PSK', 'WEP', 'WPA2-PSK'],
            score: null,
          },
          {
            name: 'Wireless Channel',
            type: 'dropdown',
            value: '',
            answer: '11',
            disabled: false,
            options: ['11', '2', '3', '7', '9', '12'],
            score: null,
          },
          {
            name: 'Controller Address',
            type: 'text',
            value: '10.0.100.51',
            answer: '10.0.100.51',
            disabled: true,
            score: null,
          },
        ],
      },

      radius_server: {
        active: false,
        properties: [
          {
            name: 'Client Address',
            type: 'text',
            value: '',
            answer: '10.0.100.51',
            disabled: false,
            score: null,
          },
          {
            name: 'Authentication Type',
            type: 'dropdown',
            value: '',
            answer: 'Microsoft AD',
            disabled: false,
            options: ['IPSec', 'L2TP', 'Microsoft AD', 'AWS IAM'],
            score: null,
          },
          {
            name: 'Server Address',
            type: 'text',
            value: '10.0.100.13',
            answer: '10.0.100.13',
            disabled: true,
            score: null,
          },
          {
            name: 'Shared Key',
            type: 'text',
            value: 'PV6JYNYQ2X',
            answer: 'PV6JYNYQ2X',
            disabled: true,
            score: null,
          },
        ],
      },

      wireless_client: {
        active: false,
        properties: [
          {
            name: 'SSID',
            type: 'text',
            value: 'SBUX-BYOD-WIFI',
            answer: 'SBUX-BYOD-WIFI',
            disabled: true,
            score: null,
          },
          {
            name: 'PSK',
            type: 'text',
            value: '',
            answer: 'PV6JYNYQ2X',
            disabled: false,
            score: null,
          },
          {
            name: 'DC NetBIOS Name',
            type: 'text',
            value: '',
            answer: 'SBUX',
            disabled: false,
            score: null,
          },
          {
            name: 'Username',
            type: 'text',
            value: '',
            answer: 'staffBYOD',
            disabled: false,
            score: null,
          },
          {
            name: 'User Password',
            type: 'text',
            value: '',
            answer: 'STAFFY37LGWSH',
            disabled: false,
            score: null,
          },
          {
            name: 'Authentication Type',
            type: 'dropdown',
            value: '',
            answer: 'WPA2-ENTERPRISE',
            disabled: false,
            options: ['WPA-ENTERPRISE', 'WPA3', 'WPA2-PERSONAL', 'WPA', 'WPA2-ENTERPRISE', 'WEP', 'OPEN', 'WPA2-PSK'],
            score: null,
          },
        ],
      },
    };
  },
  methods: {
    markSimulation() {
      let total_score = 0;

      this.wifi_controller.properties.forEach((field, field_i) => {
        if (field.value === field.answer) {
          this.wifi_controller.properties[field_i].score = true;
          total_score++;
        } else {
          this.wifi_controller.properties[field_i].score = false;
        }
      });

      this.radius_server.properties.forEach((field, field_i) => {
        if (field.value === field.answer) {
          this.radius_server.properties[field_i].score = true;
          total_score++;
        } else {
          this.radius_server.properties[field_i].score = false;
        }
      });

      this.wireless_client.properties.forEach((field, field_i) => {
        if (field.value === field.answer) {
          this.wireless_client.properties[field_i].score = true;
          total_score++;
        } else {
          this.wireless_client.properties[field_i].score = false;
        }
      });

      if (total_score === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', total_score);
      }

      this.$emit('simulation_getting_there', total_score);
    },
  },
  components: {
    Prism,
    vSelect,
    MovablePopup,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/views/platform/modules/simulations/legacy/css/CompTIA/Security+/Lgx335452482824044544.scss';
</style>

<style>
.v-select .vs__dropdown-toggle {
  background: #02050d !important;
}

.theme-dark input {
  background: #02050d;
  color: #ffffff;
}
</style>
